import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'test-page/:policyNum',
    loadChildren: () => import('./pages/test-page/test-page.module').then( m => m.TestPagePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'policy-detail/:policyNum',
    loadChildren: () => import('./pages/policy-detail/policy-detail.module').then( m => m.PolicyDetailPageModule)
  },
  {
    path: 'add-client',
    loadChildren: () => import('./pages/modals/add-client/add-client.module').then( m => m.AddClientPageModule)
  },
  {
    path: 'add-fund',
    loadChildren: () => import('./pages/modals/add-fund/add-fund.module').then( m => m.AddFundPageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./pages/modals/confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'add-rider',
    loadChildren: () => import('./pages/modals/add-rider/add-rider.module').then( m => m.AddRiderPageModule)
  },
  {
    path: 'mock-app',
    loadChildren: () => import('./pages/mock-app/mock-app.module').then( m => m.MockAppPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

