<ion-app>
  <ion-split-pane when="false" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-item-group>
          <ion-item-divider>
            <ion-item lines="none">
              <ion-label>
                <strong>Menu</strong>
              </ion-label>
            </ion-item>
          </ion-item-divider>
          <ion-menu-toggle>
            <ion-item button [routerLink]="['/home']">
              <ion-label>Inquiry Policy</ion-label>
            </ion-item>
            <ion-item button [routerLink]="['/home']">
              <ion-label>Transaction History</ion-label>
            </ion-item>
            <ion-item button [routerLink]="['/mock-app']">
              <ion-label>Mock App</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-item-group>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>